<template>
  <div class="home">
    <div class="top">
      <el-row :gutter="13">
        <el-col :span="6">
          <div class="itemBox">
            <div class="itemContent">
              <div class="left" style="background: linear-gradient(90deg, #6DEEB6 0%, #7194FC 100%);">
                <i class="iconfont iconaccess_all"></i>
              </div>
              <div class="right">
                <div>{{ $t('home.lifts') }}</div>
                <div>
                  <span class="number">{{ elevatorAllCount }}</span>
                  <span>{{ $t('home.units') }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="itemBox">
            <div class="itemContent">
              <div class="left" style="background: linear-gradient(90deg, #7194FC 0%, #AE84FF 100%);">
                <i class="iconfont icononline_all"></i>
              </div>
              <div class="right">
                <div>{{ $t('home.onlineLifts') }}</div>
                <div>
                  <span class="number">{{ elevatorOnlineCount }}</span>
                  <span>{{ $t('home.units') }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="itemBox">
            <div class="itemContent">
              <div class="left" style="background: linear-gradient(90deg, #FDBD50 0%, #FF7E48 100%);">
                <i class="iconfont icononrepair"></i>
              </div>
              <div class="right">
                <div>{{ $t('home.repairLifts') }}</div>
                <div>
                  <span class="number"> {{ workOrderNum }}</span>
                  <span>{{ $t('home.units') }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="itemBox">
            <div class="itemContent">
              <div class="left" style="background: linear-gradient(90deg, #84D6FF 0%, #6F93FB 100%);">
                <i class="iconfont iconreport_all"></i>
              </div>
              <div class="right">
                <div>{{ $t('home.parts') }}</div>
                <div>
                  <span class="number">{{ elevatorPartsNum }}</span>
                  <span>{{ $t('home.units') }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="middle">
      <el-row :gutter="16">
        <el-col :span="8">
          <div class="itemBox">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{ $t('home.elevatorStatus') }}</div>
              </div>
            </div>
            <div class="bottom">
              <ElevatorStatusEchartsPie></ElevatorStatusEchartsPie>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="itemBox">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{ $t('home.faultType') }}</div>
              </div>
              <div style="flex: 1;text-align: right;">
                <el-date-picker :picker-options="pickerOptions" style="width: 70%;border-radius: 8px;"
                  v-model="dateRange" type="daterange" :range-separator="$t('common.to')"
                  :start-placeholder="$t('common.startDate')" :end-placeholder="$t('common.endDate')"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </div>
            </div>
            <div class="bottom">
              <FaultTypeEchartsBar :date="dateRange"></FaultTypeEchartsBar>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="itemBox" v-loading="docsLoading">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{ $t('home.documents') }}</div>
              </div>
            </div>
            <div class="bottom" style="padding-top: 10px;padding-left: 28px;">
              <div v-for="(item, index) in docs" :key="index" style="margin-bottom: 15px">
                <i class="iconfont iconlinkdoc" style="font-size: 14px;margin-right: 8px;"></i>
                <el-link type="primary" style="color: #0747FD;" @click="downloadDoc(item.accessDoc.url)">{{
                  item.accessDoc.filename }}</el-link>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <el-row :gutter="16">
        <el-col :span="8">
          <div class="itemBox" v-loading="eventsLoading">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{ $t('home.events') }}</div>
              </div>
              <div style="flex: 1;text-align: right;color: #0747FD;font-size: 14px;cursor: pointer;">
                <span @click="goTo('mb')">{{ $t('common.more1') }}></span>
              </div>
            </div>
            <div class="bottom" style="padding: 0;border-radius:0 0 12px 12px">
              <el-table :show-header="false" :data="mbs" style="width: 100%;" :row-class-name="tableRowClassName">
                <el-table-column prop="elevatorName" show-overflow-tooltip></el-table-column>
                <el-table-column :label="$t('event.event')">
                  <template v-slot="scope">
                    <div v-if="$i18n.isCn">
                      <span>{{ scope.row.eventName }}</span>
                    </div>
                    <div v-else-if="$i18n.locale==='en-US'">
                      <span>{{ scope.row.eventNameEn }}</span>
                    </div>
                    <div v-else>
                      <span>{{ scope.row.eventNameItaly }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="eventTime" :label="$t('event.occurrenceTime')" width="160px"
                                 align="center"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="itemBox" v-loading="faultLoading">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{ $t('home.faults') }}</div>
              </div>
              <div style="flex: 1;text-align: right;color: #0747FD;font-size: 14px;cursor: pointer;">
                <span @click="goTo('faultRecord')">{{ $t('common.more1') }}></span>
              </div>
            </div>
            <div class="bottom" style="padding: 0;border-radius:0 0 12px 12px">
              <el-table :show-header="false" :data="faults" style="width: 100%;" :row-class-name="tableRowClassName">
                <el-table-column prop="elevatorName" show-overflow-tooltip></el-table-column>
                <el-table-column prop="faultDesc" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" width="145">
                  <template slot-scope="scoped">
                    <span style="color: #808080;">
                      {{ scoped.row.faultTime }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="itemBox" v-loading="alarmsLoading">
            <div class="top">
              <div class="itemTitle">
                <div class="line"></div>
                <div>{{ $t('home.alarms') }}</div>
              </div>
              <div style="flex: 1;text-align: right;color: #0747FD;font-size: 14px;cursor: pointer;">
                <span @click="goTo('alarmRecord')">{{ $t('common.more1') }}></span>
              </div>
            </div>
            <div class="bottom" style="padding: 0;border-radius:0 0 12px 12px">
              <el-table :show-header="false" :data="alarms" style="width: 100%;" :row-class-name="tableRowClassName">
                <el-table-column prop="elevatorName" show-overflow-tooltip></el-table-column>
                <el-table-column prop="status">
                  <template slot-scope="scoped">
                    <img v-if="scoped.row.status === 1" style="display:block;height: 19px;margin-top:2px" alt="困人"
                      src="/static/images/elevator/guanren.png"/>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" width="145">
                  <template slot-scope="scoped">
                    <span style="color: #808080;">
                      {{ scoped.row.alarmTime }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
// import VmInfoCard from "@/components/VmInfoCard.vue";
// import ChinaMap from "./China.vue";
import validateUtil from "@/util/validateUtil";
import ElevatorStatusEchartsPie from "@/views/KanBan/ElevatorStatusEchartsPie";
import FaultTypeEchartsBar from "@/views/KanBan/FaultTypeEchartsBar";

export default {
  components: {
    ElevatorStatusEchartsPie,
    FaultTypeEchartsBar,
    // VmInfoCard, ChinaMap,
  },
  data() {
    return {
      loading: false,
      eventsLoading: false,
      faultLoading: false,
      alarmsLoading: false,
      docsLoading: false,
      mbAuth: this.$auth(45),
      faultRecordAuth: this.$auth(117),
      alarmRecordAuth: this.$auth(341),
      notices: [],
      docs: [],
      mbs: [],
      faults: [],
      alarms: [],
      elevatorCountData: [],
      dateRange: [this.$moment().subtract(2, "month").format("YYYY-MM-DD"), this.$moment().format("YYYY-MM-DD")],
      elevatorOnlineCount: 0,
      workOrderNum: 0,
      elevatorPartsNum: 0,
      userCount: 0,
      barPointWidth: 20,
      bannerFiles: [],
      publicNoticeSvo: {
        pageSize: 20,
        pageNo: 1,
        allData: 1,
      },
      pieLegend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        itemMarginTop: 30,
        itemMarginBottom: 35,
      },
      faultType: {
        data: [],
        categories: [],
        color: ["#564bf5", "#008fe0", "#73c6f6", "#299458", "#5b83b9", "#9f9f9f"]
      },
      pickerOptions: {
        disabledDate: function (time) {
          return time.getFullYear() < 2022;
        }
      }
    };
  },
  computed: {
    elevatorAllCount() {
      let count = 0;
      /* eslint-disable */
      for (let item of this.elevatorCountData) {
        count = item.value + count;
      }
      return count;
    },
  },
  mounted() {
    this.getDocs();
    this.getWorkOrderCount();
    if (this.$auth(45)) {
      this.getMbs();
    }
    if (this.$auth(117)) {
      this.getFaults();
    }
    if (this.$auth(341)) {
      this.getAlarms();
    }
    this.getOnlineElevatorCount();
    this.getPartCount();
    this.getNoticeList();
    this.getElevatorCount();
    this.getUserCount();
    this.getBanners();
    this.showPwdSimple();
    setInterval(() => {
      if (this.mbAuth) {
        this.getMbs();
      }
      if (this.faultRecordAuth) {
        this.getFaults();
      }
      if (this.alarmRecordAuth) {
        this.getAlarms();
      }
    }, 1000 * 60* 10);
  },
  methods: {
    getNoticeList() {
      this.loading = true;
      let params = {
        ...this.publicNoticeSvo,
      };
      this.$api.getList("publicNotices", params).then(res => {
        this.loading = false;
        this.notices = res.data.records;
      }).catch(error => {
        this.loading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    noticeMore() {
      this.$router.push({ path: "publicNoticeMore" });
    },
    // 入网总数
    getElevatorCount() {
      this.$api.getData("elevators/count").then(res => {
        this.elevatorCountData = res.data;
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    getUserCount() {
      this.$api.getData("users/count").then(res => {
        this.userCount = res.data;
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    getBanners() {
      this.$api.getData("uiSettings/current").then(res => {
        if (res.data.banners) {
          for (const banner of JSON.parse(res.data.banners)) {
            this.bannerFiles.push({ url: this.getFullUrl(banner) });
          }
        }
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    getFullUrl(path) {
      return window.config.SERVER_URL + path;
    },
    showPwdSimple() {
      let password = this.$route.params.password;
      if (password && password.length) {
        let pass = validateUtil.validatePassword(password);
        if (!pass) {
          this.$confirm(this.$t("home.tip.passwordSimple"), this.$t("common.tips"), {
            confirmButtonText: this.$t("home.changePassword"),
            cancelButtonText: this.$t("home.ignore"),
            type: "warning",
            closeOnClickModal: false,
          }).then(() => {
            this.$router.push({
              path: "/account",
              query: { activeTab: "second" },
            });
          });
        }
      }
    },
    // 查询对接文档
    getDocs() {
      this.docsLoading = true;
      this.$api.getData("integrations/list/100").then(res => {
        this.docs = res.data;
        this.docsLoading = false;
      }).catch(error => {
        this.docsLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    // 对接文档下载
    downloadDoc(url) {
      const isHttps = "https:" === document.location.protocol;
      if (isHttps) {
        window.open(url.replace("http:", "https:"));
      } else {
        window.open(url);
      }
    },
    // 实时事件
    getMbs() {
      this.eventsLoading = true;
      let params = {
        pageSize: 20,
        homePage: true,
      }
      this.$api.getData("mbs/findRealTimeEventPage", params).then(res => {
        this.mbs = res.data.records;
        this.eventsLoading = false;
      }).catch(error => {
        this.eventsLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    // 表格偶数行高亮
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "highting-row";
      }
    },
    // 实时故障
    getFaults() {
      this.faultLoading = true;
      let params = {
        pageSize: 20,
      }
      this.$api.getData("faultRecords", params).then(res => {
        this.faults = res.data.records;
        this.faultLoading = false;
      }).catch(error => {
        this.faultLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    // 警铃报警
    getAlarms() {
      this.alarmsLoading = true;
      let params = {
        pageSize: 20,
      }
      this.$api.getData("alarmRecords", params).then(res => {
        this.alarms = res.data.records;
        this.alarmsLoading = false;
      }).catch(error => {
        this.alarmsLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    // 在线总数
    getOnlineElevatorCount() {
      this.$api.getData("elevators/countOnline").then(res => {
        this.elevatorOnlineCount = res.data;
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    // 开通日周月报告总数
    getPartCount() {
      this.$api.getData("periodicInspectionBill/count/auto").then(res => {
        this.elevatorPartsNum = res.data;
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    // 当前急修
    getWorkOrderCount() {
      this.$api.getData("workOrders/count").then(res => {
        this.workOrderNum = res.data;
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    // 更多
    goTo(route) {
      this.$router.push({
        name: route,
      }).catch(error => error);
    }
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;

  .itemTitle {
    display: flex;
    color: #4D4D4D;

    .line {
      width: 4px;
      height: 16px;
      background-color: #0747FD;
      border-radius: 100px;
      margin-right: 8px;
    }
  }

  .top {
    height: 100px;

    .itemContent {
      padding: 18px 32px;
      height: 100%;
      box-sizing: border-box;
      display: flex;

      .left {
        width: 64px;
        height: 64px;
        background-color: #f2f2f2;
        margin-right: 14px;
        border-radius: 32px;
        text-align: center;
        line-height: 64px;

        .iconfont {
          color: #fff !important;
          font-size: 35px !important;
        }
      }

      .right {
        flex: 1;
        color: #4D4D4D;

        div {
          height: 32px;

          .number {
            font-size: 40px;
            margin-right: 5px;
            color: #000;
            font-family: 'electronicFont'
          }
        }
      }
    }
  }

  .middle,
  .footer {
    height: 0;
    flex: 1;
    margin: 24px 0 16px 0;

    .itemBox {
      display: flex;
      flex-direction: column;
      position: relative;

      .top {
        display: flex;
        align-items: center;
        height: 48px;
        border-bottom: 1px solid #F2F2F2;
        justify-content: space-between;
        padding: 0 16px;
        box-sizing: border-box;
      }

      .bottom {
        flex: 1;
        overflow: auto;
        padding: 0 16px;
        box-sizing: border-box;
      }
    }
  }

  .footer {
    margin-top: 0;
    margin-bottom: 32px;
  }

  // 共用样式
  .el-row,
  .el-col {
    height: 100%;
  }

  .itemBox {
    height: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>
<style>
.home {
  .el-table {
    tr {
      td {
        &:first-child {
          padding-left: 18px !important;
        }

        &:last-child {
          padding-right: 6px !important;
        }
      }
    }
  }
}
</style>
